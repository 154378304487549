<template>
  <div style="background: #ffffff;border-radius: 8px;padding: 12px 24px 24px 24px">
    <div class="fontSize20gay text-left">编辑巡检</div>
    <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
      <div style="width: 800px;margin: auto">
        <el-form :model="pageForm" :rules="pageFormRules" ref="formRef">
          <el-form-item label="日期" prop="date" size="large" label-width="120px">
            <el-date-picker v-model="pageForm.date"
                            type="date"
                            size="large"
                            placeholder="请选择日期"
                            value-format="YYYY-MM-DD"/>
          </el-form-item>
          <el-form-item label="实验室" prop="laboratoryId" size="large" label-width="120px">
            <el-select v-model="pageForm.laboratoryId"
                       filterable
                       class="room-select-tl"
                       placeholder="选择实验室"
                       size="large">
              <el-option v-for="item in laboratoryList"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="接口无滴水" prop="drip" size="large" label-width="120px">
            <el-radio-group v-model="pageForm.drip" class="ml-4">
              <el-radio label="1" size="large">合格</el-radio>
              <el-radio label="0" size="large">不合格</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="灭火器(2个/组)" prop="fireExtinguisher" size="large" label-width="120px">
            <el-radio-group v-model="pageForm.fireExtinguisher" class="ml-4">
              <el-radio label="1" size="large">合格</el-radio>
              <el-radio label="0" size="large">不合格</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="枪头(2个/组)" prop="head" size="large" label-width="120px">
            <el-radio-group v-model="pageForm.head" class="ml-4">
              <el-radio label="1" size="large">合格</el-radio>
              <el-radio label="0" size="large">不合格</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="栓箱完好" prop="hydrant" size="large" label-width="120px">
            <el-radio-group v-model="pageForm.hydrant" class="ml-4">
              <el-radio label="1" size="large">合格</el-radio>
              <el-radio label="0" size="large">不合格</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联网正常" prop="networking" size="large" label-width="120px">
            <el-radio-group v-model="pageForm.networking" class="ml-4">
              <el-radio label="1" size="large">合格</el-radio>
              <el-radio label="0" size="large">不合格</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="盘管无破损" prop="tube" size="large" label-width="120px">
            <el-radio-group v-model="pageForm.tube" class="ml-4">
              <el-radio label="1" size="large">合格</el-radio>
              <el-radio label="0" size="large">不合格</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="水袋完好" prop="waterTape" size="large" label-width="120px">
            <el-radio-group v-model="pageForm.waterTape" class="ml-4">
              <el-radio label="1" size="large">合格</el-radio>
              <el-radio label="0" size="large">不合格</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="巡检人" prop="userId" size="large" label-width="120px">
            <el-select v-model="pageForm.userId"
                       filterable
                       class="room-select-tl"
                       placeholder="搜索巡检员"
                       size="large"
                       remote
                       :remote-method="getUserList"
                       @change="selectChange">
              <el-option v-for="item in teacherList"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id"
              />
              <template #prefix>
                <div>
                  {{ teacher.code }}
                </div>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="现场照片" prop="image" size="large" label-width="160px">
            <image-list ref="imageList"></image-list>
          </el-form-item>
          <el-form-item label="备注" prop="notes" size="large" label-width="120px">
            <el-input v-model="pageForm.notes"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      type="textarea"
            />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="marginTop15px">
      <button class="subBtn" @click="subData">保存</button>
      <button class="qxBtn" @click="qxBtn">取消</button>
    </div>
  </div>
</template>

<script>
import {addFreeHydrant, getAllLaboratory, getTeacherList} from "@/api/examination";
import ImageList from "@/view/patrolInspection/ImageList";

export default {
  name: "fireHydrants",
  components: {ImageList},
  data(){
    return {
      teacherList: [],
      teacher: {},
      laboratoryList: [],
      pageForm: {
        // 0->不合格, 1->合格
        // 日期
        date: '',
        // 接口无滴水
        drip: '1',
        // 灭火器
        fireExtinguisher: '1',
        // 枪头
        head: '1',
        // 栓箱完好
        hydrant: '1',
        // 实验室id
        laboratoryId: '',
        // 联网正常
        networking: '1',
        // 盘管无破损
        tube: '1',
        // 巡检人
        userId: '',
        // 水袋完好
        waterTape: '1',
        // 备注
        notes: '',
      },
      pageFormRules: {
        date: [
          { required: true, message: '请选择日期', trigger: 'blur' },
        ],
        drip: [
          { required: true, message: '请检验 接口无滴水 是否合格', trigger: 'blur' },
        ],
        fireExtinguisher: [
          { required: true, message: '请检 验灭火器 是否合格', trigger: 'blur' },
        ],
        head: [
          { required: true, message: '请检验 枪头 是否合格', trigger: 'blur' },
        ],
        hydrant: [
          { required: true, message: '请检验 拴箱 是否合格', trigger: 'blur' },
        ],
        laboratoryId: [
          { required: true, message: '请选择实验室', trigger: 'blur' },
        ],
        networking: [
          { required: true, message: '请检 验联网情况 是否合格', trigger: 'blur' },
        ],
        tube: [
          { required: true, message: '请检验 盘管 是否合格', trigger: 'blur' },
        ],
        userId: [
          { required: true, message: '请选择巡检员', trigger: 'blur' },
        ],
        waterTape: [
          { required: true, message: '请检验 水袋 否合格', trigger: 'blur' },
        ],
      },
      id: null,
    }
  },
  mounted() {
    this.init();
    let data=this.$root.useRoute.query;
    if(data.info){
      this.pageForm=JSON.parse(data.info);
      this.$refs.imageList.setImageInfo(this.pageForm.img);
      if(this.pageForm && this.pageForm.teacher) {
        this.getUserList(this.pageForm.teacher.name);
      }
    }
  },
  methods: {
    selectChange(e){
      this.teacher = this.teacherList.find(iter => iter.id == e) || {}
    },
    init(){
      getAllLaboratory().then(res => {
        if (res.success) {
          this.laboratoryList = res.data
        } else {
          this.$root.ElMessage.error(res.message)
        }
      })
    },
    getUserList(keyword){
      if (keyword) {
        getTeacherList({ name: keyword }).then(res => {
          if (res.success) {
            this.teacherList = res.data
          } else {
            this.$root.ElMessage.error(res.message)
          }
        })
      }
    },
    getTeachers(){

    },
    subData(){
      this.$refs.formRef.validate((valid, error) => {
        if (valid) {
          // 提交类型
          // const handler = this.id ? editQuestion : addQuestion
          const handler = addFreeHydrant
          // if (this.id) {
          //   this.pageForm.id = this.id
          // }
          let method='post';
          if(this.pageForm.id){method='put'}
          handler({ ...this.pageForm, img: this.$refs.imageList.getImg() },method).then(res => {
            if(res.success){
              this.$root.ElMessage({type:'success',message:res.message});
              setTimeout(() => {
                this.$root.useRouter.replace('/patrolInspection/fireHydrantsList')
              }, 500)
            }else{
              this.$root.ElMessage.error(res.message);
            }
          })
        } else {
          const key = Object.keys(error)[0]
          const msg = error[key][0].message
          this.$root.ElMessage.error(msg)
        }
      })
    },
    qxBtn(){
      this.$root.useRouter.go(-1);
    },
  }
}
</script>

<style lang="scss" scoped>

</style>